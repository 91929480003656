@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  
}

.typography{
  font-size: 3.5rem;
  font-weight: bold;
  background-image: url('../public/buddha.jpg'); 
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

.buddha{
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../public/buddha.jpg') fixed;
  background-size: cover;
  background-position: center fixed;  
  background-repeat: no-repeat;
}
.buddha2{
  background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.7)), url('../public/buddha.jpg') fixed;
  background-size: cover;
  background-position: center fixed;  
  background-repeat: no-repeat;
}

.contact-page{
  background: linear-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.3 )), url('./assets/contact\ page.png');
  background-size: cover;
  background-position: center;  
  background-repeat: no-repeat;
}


.cta-button{
  color: white;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all .35s;
}

.cta-button:after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border-radius: 50px;
  width: 0;
  height: 100%;
  background: white;
  transition: all .35s;
}

.cta-button:hover{
  color: black;
}

.cta-button:hover:after{
  width: 100%;
}
.fill-animation {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  animation: fill 2s forwards;
}

@keyframes fill {
  0% {
    height: 100%;
  }
  100% {
    height: 0;
  }
}

.fade-in {
  animation: fadeIn 2s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.signup-button{
  color: black;
  letter-spacing: 2px;
  text-align: center;
  position: relative;
  transition: all .35s;
}

.zoom {
  transition: transform .2s; /* Animation */
}

.zoom:hover {
  transform: scale(1.5); /* (150% zoom - Note: if the zoom is too large, it will go outside of the viewport) */
}

.signup-button:after{
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  border-radius: 50px;
  width: 0;
  height: 100%;
  background: black;
  transition: all .35s;
}

.signup-button:hover{
  color: white;
}

.signup-button:hover:after{
  width: 100%;
}



/* Imaegs loading animation shimmer effect */
.shimmer {
  height: 100%;
  background: #f6f7f8;
  background-image: linear-gradient(to right, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: shimmer;
  animation-timing-function: linear;
}

@keyframes shimmer {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}

.linearcolor{
  background: linear-gradient(1deg, #090909 0%, rgba(10.94, 10.94, 10.94, 0.74) 17%, rgba(18.06, 18.06, 18.06, 0) 60%);
}

.title{
  -webkit-text-stroke: 1px black;
}

.right-box-shadow {
  box-shadow: -5px 0px 50px -5px rgba(0, 0, 0, 0.75); /* Customize shadow values as needed */
}

.star {
  color: gold;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.star:hover {
  transform: scale(1.2);
}

input, textarea{
  transition: all 1s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  border-radius: 3px;
  padding-left: 4px;
  padding-top: 2px;
  padding-bottom: 2px;
}


input:hover, textarea:hover, input:focus, textarea:focus{
  background-color: #e7e7e7;
  outline: none;
}

@media screen and (max-width: 414px) {
 .buddha{
  background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)), url('../public/buddha.jpg') fixed;
  background-size: cover;
  background-position: center;  
  background-repeat: no-repeat;
}
}